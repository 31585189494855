const classHierarchies = {
  label: "Class Hierarchies",
  key: "classHierarchies",
  data: {
    "@context": {
      schema: "http://schema.org/",
      rdfs: "http://www.w3.org/2000/01/rdf-schema#",
    },
    "@graph": [
      {
        "@id": "schema:CreativeWork",
        "@type": "rdfs:Class",
        "rdfs:comment":
          "The most generic kind of creative work, including books, movies, photographs, software programs, etc.",
        "rdfs:label": "CreativeWork",
        "rdfs:subClassOf": {
          "@id": "schema:Thing",
        },
        "schema:source": {
          "@id":
            "http://www.w3.org/wiki/WebSchemas/SchemaDotOrgSources#source_rNews",
        },
      },
      {
        "@id": "schema:Book",
        "rdfs:subClassOf": {
          "@id": "schema:CreativeWork",
        },
      },
      {
        "@id": "schema:Movie",
        "rdfs:subClassOf": {
          "@id": "schema:CreativeWork",
        },
      },
    ],
  },
};

export default classHierarchies;
