import Button from "./Button";

export default function OperationsBar({
  handleStage,
  handleCommit,
  conn,
  ledger,
}) {
  return (
    <div className="flex space-x-2">
      <Button disabled={!(conn && ledger)} onClick={handleStage}>
        Stage Data
      </Button>
      <Button disabled={!(conn && ledger)} onClick={handleCommit}>
        Commit Data
      </Button>
    </div>
  );
}
