import { classNames } from "../lib/classNames";
import Button from "./Button";

export default function QueryBar({
  className,
  handleQueryStaged,
  handleQueryCommitted,
}) {
  return (
    <div className={classNames("flex space-x-2", className)}>
      <Button onClick={handleQueryStaged}>Query Staged</Button>
      <Button onClick={handleQueryCommitted}>Query Committed</Button>
    </div>
  );
}
