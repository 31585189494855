import { classNames } from "../lib/classNames";

export default function Button({ children, disabled = false, onClick }) {
  return (
    <button
      disabled={disabled}
      type="button"
      className={classNames(
        "inline-flex items-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2",
        disabled
          ? "bg-gray-600 focus:ring-gray-600"
          : "bg-fluree-blue hover:bg-vivid-sky-blue focus:ring-fluree-blue"
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
