import { useState, useEffect, useMemo, useCallback } from "react";
import flureedb from "@fluree/flureedb";

export const useFluree = (ledgerName) => {
  const [conn, setConn] = useState();
  const [ledger, setLedger] = useState();
  const [stagedDb, setStagedDb] = useState();
  const [committedDb, setCommittedDb] = useState();
  // const [message, setMessage] = useState("");

  const memoryConnOptions = useMemo(
    () => ({
      method: "memory",
      defaults: {
        context: {
          id: "@id",
          type: "@type",
          schema: "http://schema.org/",
          rdf: "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
          rdfs: "http://www.w3.org/2000/01/rdf-schema#",
          wiki: "https://www.wikidata.org/wiki/",
          skos: "http://www.w3.org/2008/05/skos#",
          f: "https://ns.flur.ee/ledger#",
        },
        did: {
          id: "did:fluree:TfHgFTQQiJMHaK1r1qxVPZ3Ridj9pCozqnh",
          public:
            "03b160698617e3b4cd621afd96c0591e33824cb9753ab2f1dace567884b4e242b0",
          private:
            "509553eece84d5a410f1012e8e19e84e938f226aa3ad144e2d12f36df0f51c1e",
        },
      },
    }),
    []
  );

  const connect = useCallback(
    async (options) => {
      try {
        if (!conn) {
          const newConn = await flureedb.connect(options);
          setConn(newConn);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [conn]
  );

  useEffect(() => {
    if (!conn) {
      connect(memoryConnOptions);
    }
  }, [conn, connect, memoryConnOptions]);

  const create = useCallback(
    async (ledgerName) => {
      try {
        const newLedger = await flureedb.create(conn, ledgerName);
        if (newLedger) {
          console.log("ledger created", newLedger);
          setLedger(newLedger);
        } else throw new Error(`There was a problem creating ${ledgerName}`);
      } catch (err) {
        console.error(err);
      }
    },
    [conn]
  );

  useEffect(() => {
    if (conn && !ledger) {
      create(ledgerName);
    }
  }, [conn, ledgerName, ledger, create]);

  /**
   *
   * @param {*} conn Fluree connection object
   * @param {*} db Fluree db object
   * @returns
   */
  const commit = async (conn, db) => {
    try {
      const committed = await flureedb.commit(conn, db);
      if (committed) {
        console.log("committed", committed);
        const db = await flureedb.db(ledger);
        setCommittedDb(db);
        return committed;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const stage = async (ledger, stageData) => {
    try {
      let data;
      if (typeof stageData === "string") data = JSON.parse(stageData);
      else data = stageData;
      if (ledger) {
        const staged = await flureedb.stage(ledger, data);
        if (staged) {
          setStagedDb(staged);
          console.log("data staged", data);
          return staged;
        }
      } else throw new Error("Create a new ledger first");
    } catch (err) {
      console.error(err);
    }
  };

  const query = async (db, q) => {
    try {
      let queryObject;
      if (typeof q === "string") queryObject = JSON.parse(q);
      else queryObject = q;
      const results = await flureedb.query(db, queryObject);
      return results;
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  return {
    conn,
    ledger,
    stagedDb,
    committedDb,
    stage,
    commit,
    query,
  };
};
