import Editor from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import OperationsBar from "./components/OperationsBar";
import QueryBar from "./components/QueryBar";
import ExampleTabs from "./components/ExampleTabs";
import { useFluree } from "./hooks/fluree";
import examples from "./examples";

const defaultQuery = {
  where: [["?i", "rdf:type", "schema:Movie"]],
  select: { "?i": ["*"] },
};

function App() {
  const commitSha = "9c303019fefe2d210b0867a0d3c815388ae85f56";
  const { conn, ledger, stagedDb, committedDb, stage, commit, query } =
    useFluree("test/jld");

  const [txValue, setTxValue] = useState("");
  const [queryValue, setQueryValue] = useState(
    JSON.stringify(defaultQuery, null, 2)
  );
  const [results, setResults] = useState("");
  const [currentExample, setCurrentExample] = useState("movieBook");

  const handleClickExample = (e) => {
    const newExample = e.target.name;
    if (newExample !== currentExample) {
      setCurrentExample(newExample);
    }
  };

  const handleChangeExample = (e) => {
    const newExample = e.target.value;
    if (newExample !== currentExample) {
      setCurrentExample(newExample);
    }
  };

  useEffect(() => {
    const exampleInput = examples.find(({ key }) => key === currentExample);
    if (exampleInput) setTxValue(JSON.stringify(exampleInput.data, null, 2));
  }, [currentExample]);

  const handleStage = () => {
    stage(ledger, txValue);
  };

  const handleCommit = async () => {
    let newStaged;
    if (txValue) {
      console.log("staging");
      newStaged = await stage(ledger, txValue);
    }
    await commit(conn, newStaged ?? stagedDb);
  };

  const handleQueryStaged = async () => {
    if (stagedDb) {
      const r = await query(stagedDb, queryValue);
      if (r) setResults(JSON.stringify(r, null, 2));
    }
  };

  const handleQueryCommitted = async () => {
    if (committedDb) {
      if (ledger) {
        const r = await query(committedDb, queryValue);
        if (r?.name === "Error") {
          setResults("Error: " + r.message);
        }
        else {
          setResults(JSON.stringify(r, null, 2));
        }
      }
    }
  };

  return ledger ? (
    <div className="max-w-screen-xl w-full h-screen p-2 mx-auto">
      <h1 className="text-4xl text-center text-fluree-blue">
        Fluree JSON-LD Playground
      </h1>
      <main>
        <section className="ml-8">
          <h2 className="text-fluree-blue text-2xl mb-2">Example Inputs</h2>
          <ExampleTabs
            tabs={examples}
            current={currentExample}
            onClick={handleClickExample}
            onChange={handleChangeExample}
          />
        </section>
        <div className="flex flex-col sm:flex-row space-x-1 max-h-96 h-full mt-3 mx-auto">
          <section
            aria-labelledby="input-header"
            id="input-pane"
            className="w-1/2"
          >
            <div className="flex justify-between items-center">
              <h2 id="input-header" className="text-2xl text-fluree-blue ml-8">
                Input
              </h2>
              <OperationsBar
                conn={conn}
                ledger={ledger}
                handleStage={handleStage}
                handleCommit={handleCommit}
              />
            </div>
            <div className="h-5/6 mt-3">
              <Editor
                id="input-editor"
                value={txValue}
                options={{ automaticLayout: true }}
                onChange={(value) => {
                  setTxValue(value);
                }}
                language="json"
              />
            </div>
          </section>
          <section
            aria-labelledby="query-header"
            id="query-pane"
            className="w-1/2"
          >
            <div className="flex justify-between items-center">
              <h2 id="query-header" className="text-2xl text-fluree-blue ml-8">
                Query
              </h2>
              <QueryBar
                handleQueryStaged={handleQueryStaged}
                handleQueryCommitted={handleQueryCommitted}
              />
            </div>
            <div className="h-5/6 mt-3">
              <Editor
                value={queryValue}
                options={{ automaticLayout: true }}
                onChange={(value) => {
                  setQueryValue(value);
                }}
                language="json"
              />
            </div>
          </section>
        </div>
        <section
          aria-labelledby="results-header"
          id="results-pane"
          className="w-full h-96 mt-6"
        >
          <h2 id="results-header" className="text-2xl text-fluree-blue ml-8">
            Results
          </h2>
          <div className="mt-3 h-5/6">
            <Editor
              value={results}
              language="json"
              options={{
                automaticLayout: true,
                readOnly: true,
              }}
            />
          </div>
        </section>
      </main>
      <footer className="relative bottom-2 left-2">
        <span className="text-gray-400">
          <code>@fluree/flureedb</code> built from commit{" "}
          <code>{commitSha}</code>
        </span>
      </footer>
    </div>
  ) : (
    <div className="w-full h-screen flex justify-center items-center">
      <BeatLoader color="#00A0D1" size={45} />
    </div>
  );
}

export default App;
