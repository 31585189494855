const peoplePlaces = {
  label: "People/Places",
  key: "peoplePlaces",
  data: {
    "@context": {
      schema: "http://schema.org/",
      rdf: "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
      rdfs: "http://www.w3.org/2000/01/rdf-schema#",
      wiki: "https://www.wikidata.org/wiki/",
      skos: "http://www.w3.org/2008/05/skos#",
      f: "https://ns.flur.ee/ledger#",
      ex: "http://example.org/",
      "schema:Person": {
        colleague: {
          "@container": "@set",
        },
        email: {
          "@container": "@set",
        },
      },
    },
    "@graph": [
      {
        "@id": "ex:Address/BaileyPowerPlant",
        "@type": "schema:PostalAddress",
        streetAddress: "Patterson Ave",
        addressCountry: "US",
        addressLocality: "Winston-Salem",
        addressRegion: "NC",
        latitude: 36.09989066413907,
        longitude: -80.24160792996247,
      },
      {
        "@id": "ex:Address/FlureeWinston",
        "@type": "schema:PostalAddress",
        streetAddress: "486 Patterson Ave #261",
        addressCountry: "US",
        addressLocality: "Winston-Salem",
        addressRegion: "NC",
        containedInPlace: {
          "@id": "ex:City/WinstonSalem",
        },
      },
      {
        "@id": "ex:Place/FlureeWinston",
        "@type": "schema:Place",
        address: {
          "@id": "ex:Address/FlureeWinston",
        },
        containedInPlace: {
          "@id": "ex:Place/BaileyPowerPlant",
        },
        sameAs: "https://flur.ee",
      },
      {
        "@id": "ex:Place/BaileyPowerPlant",
        "@type": "schema:Place",
        address: {
          "@id": "ex:Address/BaileyPowerPlant",
        },
        containsPlace: {
          "@id": "ex:Place/FlureeWinston",
        },
        sameAs: "https://www.innovationquarter.com/bailey-power-plant/",
      },
      {
        "@id": "ex:Address/HighPoint",
        "@type": "schema:PostalAddress",
        latitude: 35.95495649018391,
        longitude: -80.00358581494939,
        addressCountry: "US",
        addressRegion: "NC",
        addressLocality: "High Point",
      },
      {
        "@id": "ex:Address/WinstonSalem",
        "@type": "schema:PostalAddress",
        latitude: 36.108373776267065,
        longitude: -80.25719477996725,
        addressCountry: "US",
        addressRegion: "NC",
        addressLocality: "Winston-Salem",
      },
      {
        "@id": "ex:City/HighPoint",
        "@type": "schema:Place",
        name: "High Point",
        address: {
          "@id": "ex:Address/HighPoint",
        },
      },
      {
        "@id": "ex:City/WinstonSalem",
        "@type": "schema:Place",
        name: "Winston-Salem",
        address: {
          "@id": "ex:Address/WinstonSalem",
        },
      },
      {
        "@id": "ex:Person/JonathanDorety",
        "@type": "schema:Person",
        familyName: "Dorety",
        givenName: "Jonathan",
        additionalName: "David",
        workLocation: {
          "@id": "ex:Place/FlureeWinston",
        },
        email: ["jdorety@flur.ee", "jonathan.dorety@gmail.com"],
        address: {
          "@id": "ex:Address/HighPoint",
        },
        colleague: [
          {
            "@id": "ex:Person/AndrewJohnson",
          },
          {
            "@id": "ex:Person/ScottBenson",
          },
          {
            "@id": "ex:Person/ScottBenson",
          },
        ],
      },
      {
        "@id": "ex:Person/AndrewJohnson",
        "@type": "schema:Person",
        givenName: "Andrew",
        familyName: "Johnson",
        workLocation: {
          "@id": "ex:Place/FlureeWinston",
        },
        address: {
          "@id": "ex:Address/WinstonSalem",
        },
        colleague: [
          {
            "@id": "ex:Person/JonathanDorety",
          },
          {
            "@id": "ex:Person/ScottBenson",
          },
        ],
      },
      {
        "@id": "ex:Person/ScottBenson",
        "@type": "schema:Person",
        givenName: "Scott",
        familyName: "Benson",
        workLocation: {
          "@id": "ex:Place/FlureeWinston",
        },
        address: {
          "@id": "ex:Address/Midway",
          "@type": "schema:PostalAddress",
          latitude: 35.95360778716717,
          longitude: -80.2179326019886,
          addressCountry: "US",
          addressRegion: "NC",
          addressLocality: "Midway",
        },
        colleague: [
          {
            "@id": "ex:Person/AndrewJohnson",
          },
          {
            "@id": "ex:Person/JonathanDorety",
          },
        ],
      },
      {
        "@id": "ex:City/Midway",
        "@type": "schema:Place",
        name: "Midway",
        address: {
          "@id": "ex:Address/Midway",
        },
      },
    ],
  },
};

export default peoplePlaces;
